import React, { useState } from "react";

export default function Filter(props) {
  const TurnFilter = (colName) => {
    var values = [...props.filter];
    values[0][colName] = !values[0][colName];
    props.setFilter(values);
  };

  const FilterCol = (value, col) => {
    var values = [...props.table];

    value !== ""
      ? (props.filterInput[col] = value.toLowerCase()) // pridavam stlpec s hodnotu do filtrovania, ked ideme na "" tak vymazujem z filtrovania
      : delete props.filterInput[col];

    if (Object.keys(props.filterInput).length > 0) {
      // check ci nieco vobec filtrujeme
      for (var key in props.filterInput) {
        if (props.filterInput.hasOwnProperty(key)) {
          var values = values.filter((o) =>
            o[key] !== null
              ? String(o[key]).toLowerCase().includes(props.filterInput[key])
              : o[key]
          );
        }
      }
    }

    props.setFilterInput(props.filterInput);
    props.setFilteredTable(values);
  };

  return (
    <>
      <div class="table__toggle" onClick={() => TurnFilter(props.colName)}>
        <div class="icon">
          <svg>
            <use
              xmlnsXlink="http://www.w3.org/1999/xlink"
              xlinkHref="/assets/img/sprite.svg#chevron-down"
            />
          </svg>
        </div>
      </div>
      <div
        class={
          props.filter[0][props.colName]
            ? props.fixed !== undefined
              ? "table-filter--" + props.fixed + " table-filter--active"
              : "table-filter table-filter--active"
            : props.fixed !== undefined
            ? "table-filter--" + props.fixed
            : "table-filter"
        }
      >
        <div class="table-filter__in">
          <div class="table-filter-search__in">
            <div class="table-filter-search__icon">
              <div class="icon">
                <svg>
                  <use
                    xmlnsXlink="http://www.w3.org/1999/xlink"
                    xlinkHref="/assets/img/sprite.svg#search"
                  />
                </svg>
              </div>
            </div>

            <input
              type="text"
              class="table-filter-search__input form-input"
              placeholder="Hledat"
              //value={props.filterVal[0][props.colName]}
              value={props.filterInput[props.colName]}
              onChange={(e) => {
                FilterCol(e.target.value, props.colName);
              }}
            ></input>
          </div>
        </div>
      </div>
    </>
  );
}
