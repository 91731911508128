import React, { useState } from "react";
import axios from "axios";
import ModalRunning from "./modal_running";
import {
  replaceComma,
  replaceDot,
  numberWithSpaces,
  checkValidInput,
  CheckValidPaste,
} from "../helpers";
export default function FormUpEv(props) {
  return (
    <form class="form__in">
      <div class="form__item">
        <label for="id" class="form-label form-label--inline">
          Vydavatel
        </label>

        <div class="form__value">
          <input
            class="form-input"
            value={props.myArr[0]["VYDAVATEL"]}
            readOnly
            // onChange={(e) => UpdateInvoice(e.target.value)}
          />
        </div>
      </div>

      <div class="form__item">
        <label for="id" class="form-label form-label--inline">
          Verze Mediaplánu
        </label>

        <div class="form__value">
          <input
            class="form-input"
            value={props.myArr[0]["ID_MEDIAPLAN_VERSION_FE"] + ".0"}
            readOnly
            //  onChange={(e) => UpdateInvoice(e.target.value)}
          />
        </div>
      </div>

      <div class="form__item">
        <label for="id" class="form-label form-label--inline">
          Číslo faktury
        </label>

        <div class="form__value">
          <input
            class="form-input"
            value={props.myArr[0]["INVOICE_ALIAS"]}
            readOnly
            // onChange={(e) => UpdateInvoice(e.target.value)}
          />
        </div>
      </div>

      <div class="form__item">
        <label for="id" class="form-label form-label--inline">
          Fakturované období
        </label>

        <div class="form__value">
          <input
            class="form-input"
            value={props.myArr[0]["DATUM_UVEREJNENI"]}
            readOnly
            //   onChange={(e) => UpdateInvoice(e.target.value)}
          />
        </div>
      </div>

      <div class="form__item">
        <label for="id" class="form-label form-label--inline">
          Agentura NetNet FP
        </label>

        <div class="form__value">
          <input
            class="form-input"
            value={numberWithSpaces(props.myArr[0]["MNNN"])}
            readOnly
            //  onChange={(e) => UpdateInvoice(e.target.value, "MNR")}
          />
        </div>
      </div>
      <div class="form__item">
        <label for="id" class="form-label form-label--inline">
          Agentura NetNet MP
        </label>

        <div class="form__value">
          <input
            class="form-input"
            value={numberWithSpaces(props.myArr[0]["MNNN_MP_D"])}
            readOnly
            //  onChange={(e) => UpdateInvoice(e.target.value, "MNR")}
          />
        </div>
      </div>
      <div class="form__item">
        <label for="id" class="form-label form-label--inline">
          Klient NetNet MP
        </label>

        <div class="form__value">
          <input
            class="form-input"
            value={numberWithSpaces(props.myArr[0]["MNNPMP"])}
            readOnly
          />
        </div>
      </div>
      <div class="form__item">
        <label for="id" class="form-label form-label--inline">
          Fee MP
        </label>

        <div class="form__value">
          <input
            class="form-input"
            value={
              props.myArr[0]["FEE_MP"] == null
                ? 0
                : numberWithSpaces(props.myArr[0]["FEE_MP"])
            }
            readOnly
          />
        </div>
      </div>
      <div class="form__item">
        <label for="id" class="form-label form-label--inline">
          Klient NetNet FV
        </label>

        <div class="form__value">
          <input
            class={
              (props.myArr[0]["ID_INVOICE_TO_HELIOS"] == null) |
              (props.user == 1)
                ? "form-input"
                : "form-input form-input--invalid"
            }
            value={replaceDot(props.myArr[0]["MNNP"])}
            {...((props.myArr[0]["ID_INVOICE_TO_HELIOS"] == null) |
            (props.user == 1)
              ? props.user !== 4
                ? {
                    onChange: (e) => props.UpdateVal(e.target.value, "MNNP"),
                  }
                : { readOnly: true }
              : {})}
            onKeyDown={(e) => checkValidInput(e)}
            onPaste={(e) => CheckValidPaste(e)}
          />
        </div>
      </div>
      <div class="form__item">
        <label for="id" class="form-label form-label--inline">
          Fee
        </label>

        <div class="form__value">
          <input
            class={
              (props.myArr[0]["ID_INVOICE_TO_HELIOS"] == null) |
              (props.user == 1)
                ? props.mnnpUpdate & (props.myArr[0]["FEE_MP"] > 0)
                  ? "form-input form-input--valid"
                  : "form-input"
                : "form-input form-input--invalid"
            }
            value={replaceDot(props.myArr[0]["FEE"])}
            {...((props.myArr[0]["ID_INVOICE_TO_HELIOS"] == null) |
            (props.user == 1)
              ? props.user !== 4
                ? {
                    onChange: (e) => props.UpdateVal(e.target.value, "FEE"),
                  }
                : { readOnly: true }
              : {})}
            onKeyDown={(e) => checkValidInput(e)}
            onPaste={(e) => CheckValidPaste(e)}
          />
        </div>
      </div>

      <div class="form__item">
        <label for="id" class="form-label form-label--inline">
          Cost to Client
        </label>

        <div class="form__value">
          <input
            class={"form-input"}
            value={numberWithSpaces(
              replaceComma(props.myArr[0]["MNNP"]) +
                replaceComma(props.myArr[0]["FEE"])
            )}
            readOnly
          />
        </div>
      </div>

      <div class="form__item">
        <label for="id" class="form-label form-label--inline">
          Delta (prodej - nákup)
        </label>

        <div class="form__value">
          <input
            class="form-input"
            value={numberWithSpaces(
              replaceComma(props.myArr[0]["MNNP"]) - props.myArr[0]["MNNN"]
            )}
            readOnly
          />
        </div>
      </div>

      <div class="form__item">
        <label for="id" class="form-label form-label--inline">
          Vysvětlení delty
        </label>

        <div class="form__value">
          {props.user != 4 ? (
            <select
              class="form-select"
              value={props.myArr[0]["MNNP_MNNN_FI_ID"]}
              onChange={(e) =>
                props.UpdateVal(e.target.value, "MNNP_MNNN_FI_ID")
              }
            >
              <option value="" selected disabled hidden></option>
              <option value={1}>Kurzový rozdíl</option>
              <option value={2}>Mediální delta</option>
              <option value={3}>Nemediální delta</option>
              <option value={4}>Fakturace v jiném měsíci</option>
              <option value={5}>Kombinace více možnosti</option>
              <option value={6}>Ostatní</option>
            </select>
          ) : (
            <input
              class="form-input"
              value={props.myArr[0]["MNNP_MNNN_FI_ID_LABEL"]}
              readOnly
              //   onChange={(e) => UpdateInvoice(e.target.value, "MNR")}
            />
          )}
          {props.validace === 1 ? (
            <div class="validation">
              <div class="validation__item">Vyberte vysvětlení!</div>
            </div>
          ) : (
            <></>
          )}
        </div>
      </div>

      <div class="form__item">
        <label for="id" class="form-label form-label--inline">
          Komentář
        </label>

        <div class="form__value">
          <input
            class="form-input"
            value={props.myArr[0]["NOTE_1"]}
            onChange={(e) => props.UpdateVal(e.target.value, "NOTE_1")}
          />
          {props.validace === 3 ? (
            <div class="validation">
              <div class="validation__item">Zdůvodněte!</div>
            </div>
          ) : (
            <></>
          )}
        </div>
      </div>
    </form>
  );
}
