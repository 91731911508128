import React, { useState } from "react";
import axios from "axios";
import fileDownload from "js-file-download";
import ModalRunning from "./modal_running";
import { checkValidInput } from "../helpers";

export default function MediaplanModalExport(props) {
  const [isRunning, setIsRunning] = useState(false);
  const [rozpadStopaz, setRozpadStopaz] = useState(true);
  const [arg1, setArg1] = useState(true);
  const [arg2, setArg2] = useState(true);
  const [arg3, setArg3] = useState(true);
  const [arg4, setArg4] = useState(true);
  const [arg7, setArg7] = useState(null);
  const [tabPozice, setTabPozice] = useState(true);

  const [at, setAt] = useState(true);
  const [seznam, setSeznam] = useState(true);
  const [barrand, setBarrand] = useState(true);

  const toggleModal = () => {
    props.setModalex(!props.modalex);
  };

  let urldown;

  const download = async () => {
    var url_query_string;

    //parametre pre TV export
    var tv_params =
      props.vybranyMediatyp == "TV"
        ? "&arg1=" +
          arg1 +
          "&arg2=" +
          arg2 +
          "&arg3=" +
          arg3 +
          "&arg4=" +
          arg4 +
          "&arg7=" +
          (arg7 !== null ? arg7.toString().replace(",", ".") : arg7) +
          "&rozpadStopaz=" +
          rozpadStopaz +
          "&tabPozice=" +
          tabPozice +
          "&at=" +
          at +
          "&seznam=" +
          seznam +
          "&barrand=" +
          barrand
        : "";

    props.modalExType !== "dod"
      ? (url_query_string =
          "/downloadmediaplan?mediatyp=" +
          props.vybranyMediatyp +
          "&mediaplan=" +
          props.vybranyMediaplan +
          tv_params)
      : (url_query_string =
          "/downloadmediaplandod?mediatyp=" +
          props.vybranyMediatyp +
          "&mediaplan=" +
          props.vybranyMediaplan);

    setIsRunning(true);

    await axios
      .get(url_query_string)
      .then((res) => {
        console.log(res);
        urldown = res.data;
      })
      .catch((err) => {
        console.log(err);
        setIsRunning(false);
      });

    //na presignedurl pouzivame fetch lebo ta nejde posielat TOKENY!!!!
    const uninterceptedAxiosInstance = axios.create();

    uninterceptedAxiosInstance(urldown, {
      method: "GET", //,
      responseType: "blob",
    })
      .then((res) => {
        props.modalExType === "dod"
          ? fileDownload(res.data, props.vybranyMediaplanFN + ".zip")
          : fileDownload(res.data, props.vybranyMediaplanFN + ".xlsx");

        setIsRunning(false);
        props.setModalex(!props.modalex);
      })
      .catch((err) => {
        console.log(err);
        setIsRunning(false);
      });
  };

  return (
    <>
      {props.modalex && (
        <div class="modal-window">
          <ModalRunning
            isRunning={isRunning}
            setIsRunning={setIsRunning}
            message={"Exportuji mediaplán."}
          />

          <div class="modal-window__wrap">
            <div class="modal-window__close" onClick={toggleModal}>
              <div class="icon">
                <svg>
                  <use
                    xmlnsXlink="http://www.w3.org/1999/xlink"
                    xlinkHref="/assets/img/sprite.svg#cross"
                  />
                </svg>
              </div>
            </div>
            <div class="modal-window__in">
              <div class="modal-window__title">
                {props.modalExType == "dod"
                  ? "Export dodavatelského mediaplánu verze " +
                    props.vybranyMediaplanFE +
                    ".0"
                  : "Export klientského mediaplánu verze " +
                    props.vybranyMediaplanFE +
                    ".0"}
              </div>
              {props.vybranyMediatyp == "TV" ? (
                <div class="modal-window__content">
                  <div class="form">
                    <form class="form__in">
                      <div class="form__item">
                        <div class="form-checkbox">
                          <input
                            type="checkbox"
                            checked={rozpadStopaz}
                            onChange={() => {
                              setRozpadStopaz(!rozpadStopaz);
                            }}
                            id={"rozpadStopaz"}
                          />
                          <label htmlFor={"rozpadStopaz"}>
                            Rozpadnout stopáž
                          </label>
                        </div>
                      </div>
                      <div class="form__item">
                        <div class="form-checkbox">
                          <input
                            type="checkbox"
                            checked={arg1}
                            onChange={() => {
                              setArg1(!arg1);
                            }}
                            id={"Arg1"}
                          />
                          <label htmlFor={"Arg1"}> Cena NET NET</label>
                        </div>
                      </div>
                      <div class="form__item">
                        <div class="form-checkbox">
                          <input
                            type="checkbox"
                            checked={arg2}
                            onChange={() => {
                              setArg2(!arg2);
                            }}
                            id={"Arg2"}
                          />
                          <label htmlFor={"Arg2"}>
                            {" "}
                            OVD % a OVD GRPs (Negarantované)
                          </label>
                        </div>
                      </div>
                      <div class="form__item">
                        <div class="form-checkbox">
                          <input
                            type="checkbox"
                            checked={arg3}
                            onChange={() => {
                              setArg3(!arg3);
                            }}
                            id={"Arg3"}
                          />
                          <label htmlFor={"Arg3"}> GRPs PT+SPT</label>
                        </div>
                      </div>
                      <div class="form__item">
                        <div class="form-checkbox">
                          <input
                            type="checkbox"
                            checked={arg4}
                            onChange={() => {
                              setArg4(!arg4);
                            }}
                            id={"arg4"}
                          />
                          <label htmlFor={"arg4"}>
                            {" "}
                            Odhad sledovanosti (TRP, GRP, TAI)
                          </label>
                        </div>
                      </div>
                      <div class="form__item">
                        <div class="form-checkbox">
                          <input
                            type="checkbox"
                            checked={tabPozice}
                            onChange={() => {
                              setTabPozice(!tabPozice);
                            }}
                            id={"tabPozice"}
                          />
                          <label htmlFor={"tabPozice"}>Tabulka Pozice</label>
                        </div>
                      </div>

                      {arg4 && (
                        <div class="form__item">
                          <label for="id" class="form-label form-label--inline">
                            % GRPs umistěny formou e-GRPs
                          </label>

                          <div class="form__value">
                            <input
                              class="form-input"
                              onKeyDown={(e) => checkValidInput(e)}
                              value={arg7}
                              onChange={(e) => setArg7(e.target.value)}
                            />
                          </div>
                        </div>
                      )}

                      <div class="form__item">
                        <label for="id" class="form-label form-label--inline">
                          TV skupiny zobrazené samostatně:
                        </label>
                      </div>
                      <div class="form__item">
                        <div class="form-checkbox">
                          <input
                            type="checkbox"
                            checked={at}
                            onChange={() => {
                              setAt(!at);
                            }}
                            id={"at"}
                          />
                          <label htmlFor={"at"}>AT media</label>
                        </div>
                      </div>
                      <div class="form__item">
                        <div class="form-checkbox">
                          <input
                            type="checkbox"
                            checked={seznam}
                            onChange={() => {
                              setSeznam(!seznam);
                            }}
                            id={"seznam"}
                          />
                          <label htmlFor={"seznam"}>Seznam TV</label>
                        </div>
                      </div>
                      <div class="form__item">
                        <div class="form-checkbox">
                          <input
                            type="checkbox"
                            checked={barrand}
                            onChange={() => {
                              setBarrand(!barrand);
                            }}
                            id={"barrand"}
                          />
                          <label htmlFor={"barrand"}>Barrandov Group</label>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              ) : (
                <></>
              )}

              <div class="modal-window__actions">
                <a className="btn btn--medium btn--primary" onClick={download}>
                  Stáhnout
                </a>
                <a class="btn btn--medium btn--bordered" onClick={toggleModal}>
                  Storno
                </a>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
}
