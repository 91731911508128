import axios from "axios";
import React, { useEffect, useState, useRef } from "react";
import { useParams } from "react-router-dom";
import MediaplanModalExport from "./Order-Mediaplan-Modal_export";
import MediaplanModalImport from "./Order-Mediaplan-Modal_import";
import MediaplanModalApproval from "./Order-Mediaplan-Modal_approval";
import OrderModalProcessContinuation from "./Order-Modal_process_continuation";
import ModalInfo from "./modal_info";
import { formatDate, numberWithSpaces, cutFile, myfunction } from "../helpers";
import ModalConfirmationBox from "./modal_confirmation_box";
import ModalRunningWhite from "./modal_running_white";
import Loader from "./Loader_fixed";
import MediaplanModalInput from "./Order-Mediaplan-Modal_import_input";
import Filter from "./Filter";

export default function Mediaplan(props) {
  const [filter, setFilter] = useState([{ INIT: false }]);
  const [filterInput, setFilterInput] = useState([]);
  const [filterVal, setFilterVal] = useState([{ INIT: "" }]);

  const [mediaplanVerzia, setMediaplanVerzia] = useState([]);
  const [mediaplan, setMediaplan] = useState([]);
  const [mediaplanF, setMediaplanF] = useState([]);
  const [mediaplanSP, setMediaplanSP] = useState([]);
  const [mediaplanSPF, setMediaplanSPF] = useState([]);

  const [vybranyMediaplan, setVybranyMediaplan] = useState(0);
  const [vybranyMediaplanFE, setVybranyMediaplanFE] = useState(0);
  const [vybranyMediaplanFN, setVybranyMediaplanFN] = useState(0);

  const [newImport, setNewImport] = useState(false);
  const [modal, setModal] = useState(false);
  const [modalex, setModalex] = useState(false);
  const [modalExType, setModalExType] = useState("");
  const [modalConfirmation, setModalConfirmation] = useState(false);
  const [substitution, setSubstitution] = useState(false);
  const [spotList, setSpotList] = useState(false);

  const [modalapp, setModalapp] = useState(false);

  const [mediaplanToApprove, setMediaplanToApprove] = useState(0);
  const [mediaplanToApproveValue, setMediaplanToApproveValue] = useState(0);
  const [mediaplanToApproveVersion, setMediaplanToApproveVersion] =
    useState("");

  const [isLoading, setLoading] = useState(true);

  const [processType, setProcessType] = useState(3);
  const [processToInvoke, setProcessToInvoke] = useState(false);
  const [processFrom, setProcessFrom] = useState("");

  const [isRunning, setIsRunning] = useState(false);
  const [infoMessage, setInfoMessage] = useState("");
  const [isError, setIsError] = useState(false);
  const [infoBox, setInfoBox] = useState("");

  const params = useParams();

  const [subModal, setSubModal] = useState(false);
  const [inputData, setInputData] = useState([]); // z backendu pride matica pri TV 1. faze importu, ktoru len updatuju a posielame naspat na BE
  const [loadType, setLoadType] = useState("nemerene"); //pre TV ci nahravame s MP alebo bez
  const [refresh, setRefresh] = useState(false); //pre TV ci nahravame s MP alebo bez

  const inputDataZeros = [
    {
      BRUTTO: 0,
      CLIENT_NET: 0,
      ID_MEDIAPLAN_VERSION: 0,
      OBCHODNI_SKUPINY: "Nova Group",
      OVD_GRPS_PERCENT: 0,
      POCET_SPOTU: 0,
      STOPAZ: "Celkem",
      index: 0,
    },
    {
      BRUTTO: 0,
      CLIENT_NET: 0,
      ID_MEDIAPLAN_VERSION: 0,
      OBCHODNI_SKUPINY: "Prima Group",
      OVD_GRPS_PERCENT: 0,
      POCET_SPOTU: 0,
      STOPAZ: "Celkem",
      index: 1,
    },
    {
      BRUTTO: 0,
      CLIENT_NET: 0,
      ID_MEDIAPLAN_VERSION: 0,
      OBCHODNI_SKUPINY: "Nova Sport",
      OVD_GRPS_PERCENT: 0,
      POCET_SPOTU: 0,
      STOPAZ: "Celkem",
      index: 2,
    },
    {
      BRUTTO: 0,
      CLIENT_NET: 0,
      ID_MEDIAPLAN_VERSION: 0,
      OBCHODNI_SKUPINY: "Barrandov Group",
      OVD_GRPS_PERCENT: 0,
      POCET_SPOTU: 0,
      STOPAZ: "Celkem",
      index: 3,
    },
    {
      BRUTTO: 0,
      CLIENT_NET: 0,
      ID_MEDIAPLAN_VERSION: 0,
      OBCHODNI_SKUPINY: "AT Media",
      OVD_GRPS_PERCENT: 0,
      POCET_SPOTU: 0,
      STOPAZ: "Celkem",
      index: 4,
    },
    {
      BRUTTO: 0,
      CLIENT_NET: 0,
      ID_MEDIAPLAN_VERSION: 0,
      OBCHODNI_SKUPINY: "Seznam TV",
      OVD_GRPS_PERCENT: 0,
      POCET_SPOTU: 0,
      STOPAZ: "Celkem",
      index: 5,
    },
    {
      BRUTTO: 0,
      CLIENT_NET: 0,
      ID_MEDIAPLAN_VERSION: 0,
      OBCHODNI_SKUPINY: "CT Group",
      OVD_GRPS_PERCENT: 0,
      POCET_SPOTU: 0,
      STOPAZ: "Celkem",
      index: 6,
    },
    {
      BRUTTO: 0,
      CLIENT_NET: 0,
      ID_MEDIAPLAN_VERSION: 0,
      OBCHODNI_SKUPINY: "O2 TV",
      OVD_GRPS_PERCENT: 0,
      POCET_SPOTU: 0,
      STOPAZ: "Celkem",
      index: 7,
    },
    {
      BRUTTO: 0,
      CLIENT_NET: 0,
      ID_MEDIAPLAN_VERSION: 0,
      OBCHODNI_SKUPINY: "Media one",
      OVD_GRPS_PERCENT: 0,
      POCET_SPOTU: 0,
      STOPAZ: "Celkem",
      index: 8,
    },
    {
      BRUTTO: 0,
      CLIENT_NET: 0,
      ID_MEDIAPLAN_VERSION: 0,
      OBCHODNI_SKUPINY: "Óčko",
      OVD_GRPS_PERCENT: 0,
      POCET_SPOTU: 0,
      STOPAZ: "Celkem",
      index: 9,
    },
    {
      BRUTTO: 0,
      CLIENT_NET: 0,
      ID_MEDIAPLAN_VERSION: 0,
      OBCHODNI_SKUPINY: "Sporty TV",
      OVD_GRPS_PERCENT: 0,
      POCET_SPOTU: 0,
      STOPAZ: "Celkem",
      index: 10,
    },
  ];

  useEffect(() => {
    const url_query_string =
      "/mediaplanversion?kampan_id=" +
      params.zakazka +
      "&mediatyp=" +
      params.mediatyp;

    axios.get(url_query_string).then(
      (res) => {
        console.log(res);
        SetUpStates(res);
        setLoading(false);
      },
      (err) => {
        console.log(err);
      }
    );
  }, [refresh]);

  const sortMV = (data) => {
    const values = data;
    values.sort((a, b) => b.ID - a.ID);
    setMediaplanVerzia(values);
    console.log(values);
  };

  const handleCheck = (event, index, verze) => {
    setMediaplanToApprove(index);
    setMediaplanToApproveValue(event.target.checked);
    setMediaplanToApproveVersion(verze);
    setModalapp(!modalapp);
  };

  const GetMediaplanFeAndFileName = () => {
    const values = [...mediaplanVerzia];
    const mv = values.find((x) => x.ID === vybranyMediaplan);

    setVybranyMediaplanFE(mv["ID_FE"]);
    setVybranyMediaplanFN(cutFile(mv["S3_KEY"]));
  };

  const RemoveMediaplan = () => {
    const url_query_string =
      "/mediaplanversion?kampan_id=" +
      params.zakazka +
      "&mediatyp=" +
      params.mediatyp +
      "&id_mv=" +
      vybranyMediaplan;

    setIsRunning(true);
    setInfoMessage("Odstraňuji mediaplán");

    axios.post(url_query_string).then(
      (res) => {
        SetUpStates(res);
        setModalConfirmation(false);
        setIsRunning(false);
      },
      (err) => {
        console.log(err);
        setIsRunning(false);
        setIsError(true);
        setInfoBox("Došlo k chybě!");
      }
    );
  };

  const SetUpStates = (res) => {
    setMediaplanVerzia(res.data[0]["MV"]);
    setMediaplan(res.data[0]["M"]);
    setMediaplanF(res.data[0]["M"]);

    if (params.mediatyp == "TV") {
      setMediaplanSP(res.data[0]["M_SP"]);
      setMediaplanSPF(res.data[0]["M_SP"]);
    }
    const aktualny_mediaplan = Math.max(...res.data[0]["MV"].map((o) => o.ID));
    const aktualny_mediaplan_fe = Math.max(
      ...res.data[0]["MV"].map((o) => o.ID_FE)
    );
    setVybranyMediaplan(aktualny_mediaplan);
    setVybranyMediaplanFE(aktualny_mediaplan_fe);

    sortMV(res.data[0]["MV"]);
  };

  const NemereneStaniceImport = () => {
    setInputData(inputDataZeros);
    setLoadType("nemerene");
    setSubModal(true);
  };
  /*if (isLoading) {
    return <Loader message="" />;
  }
*/

  const headerRef = useRef(null);
  const [leftOffset, setLeftOffset] = useState(0);

  useEffect(() => {
    myfunction(headerRef, leftOffset, setLeftOffset, 270, window);
  }, [leftOffset]);

  return (
    <>
      <MediaplanModalImport
        vybranaZakazka={params.zakazka}
        vybranyMediatyp={params.mediatyp}
        setMediaplanVerzia={setMediaplanVerzia}
        setMediaplan={setMediaplan}
        setMediaplanSP={setMediaplanSP}
        modal={modal}
        setModal={setModal}
        sortMV={sortMV}
        setVybranyMediaplan={setVybranyMediaplan}
        vybranyMediaplan={vybranyMediaplan}
        setProcessType={setProcessType}
        setProcessToInvoke={setProcessToInvoke}
        setProcessFrom={setProcessFrom}
        setIsError={setIsError}
        setInfoBox={setInfoBox}
        substitution={substitution}
        newImport={newImport}
        refresh={refresh}
        setRefresh={setRefresh}
        spotList={spotList}
        setSpotList={setSpotList}
      />
      <MediaplanModalInput
        vybranyMediatyp={params.mediatyp}
        vybranaZakazka={params.zakazka}
        subModal={subModal}
        setSubModal={setSubModal}
        inputData={inputData}
        setInputData={setInputData}
        setMediaplanVerzia={props.setMediaplanVerzia}
        setMediaplan={props.setMediaplan}
        sortMV={props.sortMV}
        setVybranyMediaplan={props.setVybranyMediaplan}
        vybranyMediaplan={props.vybranyMediaplan}
        setProcessType={props.setProcessType}
        setProcessToInvoke={props.setProcessToInvoke}
        setProcessFrom={props.setProcessFrom}
        loadType={loadType}
      />
      <MediaplanModalExport
        vybranyMediatyp={params.mediatyp}
        vybranyMediaplanFE={vybranyMediaplanFE}
        vybranyMediaplanFN={vybranyMediaplanFN}
        vybranyMediaplan={vybranyMediaplan}
        modalex={modalex}
        setModalex={setModalex}
        modalExType={modalExType}
      />
      <MediaplanModalApproval
        mediaplanToApprove={mediaplanToApprove}
        mediaplanToApproveValue={mediaplanToApproveValue}
        mediaplanToApproveVersion={mediaplanToApproveVersion}
        modalapp={modalapp}
        setModalapp={setModalapp}
        mediaplanVerzia={mediaplanVerzia}
        setMediaplanVerzia={setMediaplanVerzia}
        vybranaZakazka={params.zakazka}
        vybranyMediatyp={params.mediatyp}
        setProcessFrom={setProcessFrom}
        setProcessToInvoke={setProcessToInvoke}
        setIsError={setIsError}
        setInfoBox={setInfoBox}
      />
      <OrderModalProcessContinuation
        processType={processType}
        processToInvoke={processToInvoke}
        processFrom={processFrom}
        setProcessToInvoke={setProcessToInvoke}
        vybranaZakazka={params.zakazka}
      />
      <ModalConfirmationBox
        setModalConfirmation={setModalConfirmation}
        modalConfirmation={modalConfirmation}
        Confirm={RemoveMediaplan}
        textConfirmation={
          "Opravdu chcete odstranit verzi mediaplánu " +
          vybranyMediaplanFE +
          ".0?"
        }
      />
      <ModalRunningWhite
        isRunning={isRunning}
        setIsRunning={setIsRunning}
        message={infoMessage}
      />
      <ModalInfo
        isError={isError}
        setIsError={setIsError}
        infoBox={infoBox}
        setInfoBox={setInfoBox}
      />
      <main class="content">
        {isLoading ? (
          <Loader message="" />
        ) : (
          <div class="container">
            <div class="content__in">
              <div class="content__side content__side-w280">
                <div class="side-panel">
                  <div class="panel-info">
                    <div class="panel-info-table">
                      <div class="panel-info-table__head">
                        <div class="panel-info-table__row">
                          <div class="panel-info-table__col panel-info-table__col--40">
                            Verze
                          </div>

                          <div class="panel-info-table__col panel-info-table__col--flexbile">
                            Název
                          </div>

                          <div class="panel-info-table__col panel-info-table__col--w40">
                            Schváleno
                          </div>
                        </div>
                      </div>

                      <div class="panel-info-table__body">
                        {mediaplanVerzia.map((mVerzia, index) => (
                          <div
                            class={
                              mVerzia.ID === vybranyMediaplan
                                ? "panel-info-table__row--active"
                                : "panel-info-table__row"
                            }
                            key={mVerzia.ID_FE}
                          >
                            <div
                              class="panel-info-table__col panel-info-table__col--w30"
                              onClick={() => {
                                setVybranyMediaplan(mVerzia.ID);
                                setVybranyMediaplanFE(mVerzia.ID_FE);
                              }}
                              style={{ cursor: "pointer" }}
                            >
                              <span>
                                {mediaplanSP.some(
                                  (o) => o.ID_MEDIAPLAN_VERSION === mVerzia.ID
                                )
                                  ? mVerzia.ID_FE + ".0s"
                                  : mVerzia.ID_FE + ".0"}{" "}
                              </span>
                            </div>

                            <div class="panel-info-table__col panel-info-table__col--flexbile">
                              <span>{cutFile(mVerzia.S3_KEY)} </span>
                            </div>

                            <div class="panel-info-table__col panel-info-table__col--w30">
                              {props.user > 4 ? (
                                mVerzia.ID_MEDIAPLAN_VERSION !== null ? (
                                  <span>
                                    {mVerzia.ID_MEDIAPLAN_VERSION + ".0"}
                                  </span>
                                ) : (
                                  <input
                                    type="checkbox"
                                    checked={mVerzia.CONFIRMED}
                                    // onChange={(event) => handleCheck(event, index)}
                                    // onChange="return false;"
                                  ></input>
                                )
                              ) : mVerzia.ID_MEDIAPLAN_VERSION !== null ? (
                                <span>
                                  {mVerzia.ID_MEDIAPLAN_VERSION + ".0"}
                                </span>
                              ) : (
                                <input
                                  type="checkbox"
                                  checked={
                                    mVerzia.CONFIRMED == 1 ? true : false
                                  }
                                  onChange={(event) =>
                                    handleCheck(
                                      event,
                                      index,
                                      mVerzia.ID_FE + ".0"
                                    )
                                  }
                                  // onChange="return false;"
                                ></input>
                              )}
                              <label for="panelInfo1"></label>
                            </div>
                          </div>
                        ))}
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div class="content__main">
                <div class="table-container">
                  <div class="table">
                    <div class="table__head" ref={headerRef}>
                      <div class="table__row table__row--bold">
                        {params.mediatyp == "TV" ? (
                          mediaplan.some(
                            (o) => o.ID_MEDIAPLAN_VERSION == vybranyMediaplan
                          ) ? (
                            <>
                              <div class="table__col table__col--w200">
                                <label>Dodavatel</label>
                                <Filter
                                  filter={filter}
                                  setFilter={setFilter}
                                  filterVal={filterVal}
                                  setFilterVal={setFilterVal}
                                  table={mediaplan}
                                  filterInput={filterInput}
                                  setFilterInput={setFilterInput}
                                  setFilteredTable={setMediaplanF}
                                  colName={"OBCHODNI_SKUPINY"}
                                />
                              </div>
                              <div class="table__col table__col--w120">
                                <label>Stopáž</label>
                                <Filter
                                  filter={filter}
                                  setFilter={setFilter}
                                  filterVal={filterVal}
                                  setFilterVal={setFilterVal}
                                  table={mediaplan}
                                  filterInput={filterInput}
                                  setFilterInput={setFilterInput}
                                  setFilteredTable={setMediaplanF}
                                  colName={"STOPAZ"}
                                />
                              </div>
                              {props.user >= 4 || props.user == 1 ? (
                                <div class="table__col table__col--w200  table__col--agentura-header">
                                  <label>Agentura NetNet s příplatky</label>
                                </div>
                              ) : (
                                <></>
                              )}
                              <div class="table__col table__col--w200  table__col--klient-header">
                                <label>Klient NetNet s příplatky</label>
                              </div>
                              <div class="table__col table__col--w160 table__col--klient-header">
                                <label>GRPs</label>
                              </div>
                              <div class="table__col table__col--w160 table__col--klient-header">
                                <label>TRPs</label>
                              </div>
                              <div class="table__col table__col--w160 table__col--klient-header">
                                <label>Affinity</label>
                              </div>
                              <div class="table__col table__col--flexible table__col--klient-header">
                                <label>Počet spotů</label>
                              </div>
                            </>
                          ) : (
                            <>
                              <div class="table__col table__col--w200">
                                <label>Vydavatel</label>
                                <Filter
                                  filter={filter}
                                  setFilter={setFilter}
                                  filterVal={filterVal}
                                  setFilterVal={setFilterVal}
                                  table={mediaplanSP}
                                  filterInput={filterInput}
                                  setFilterInput={setFilterInput}
                                  setFilteredTable={setMediaplanSPF}
                                  colName={"VYDAVATEL"}
                                />
                              </div>
                              <div class="table__col table__col--w200">
                                <label>Gross</label>
                              </div>
                              <div class="table__col table__col--w200">
                                <label>Media Net Net</label>
                              </div>
                              <div class="table__col table__col--w160">
                                <label>GRPs</label>
                              </div>
                              <div class="table__col table__col--w160">
                                <label>TRPs</label>
                              </div>
                              <div class="table__col table__col--flexible">
                                <label>Affinity</label>
                              </div>
                            </>
                          )
                        ) : (
                          <></>
                        )}
                        {params.mediatyp == "Print" ||
                        params.mediatyp == "Radio" ? (
                          <>
                            <div class="table__col table__col--w200">
                              <label>Dodavatel</label>
                              <Filter
                                filter={filter}
                                setFilter={setFilter}
                                filterVal={filterVal}
                                setFilterVal={setFilterVal}
                                table={mediaplan}
                                filterInput={filterInput}
                                setFilterInput={setFilterInput}
                                setFilteredTable={setMediaplanF}
                                colName={"VYDAVATEL"}
                              />
                            </div>
                            <div class="table__col table__col--w200">
                              <label>Název média</label>
                              <Filter
                                filter={filter}
                                setFilter={setFilter}
                                filterVal={filterVal}
                                setFilterVal={setFilterVal}
                                table={mediaplan}
                                filterInput={filterInput}
                                setFilterInput={setFilterInput}
                                setFilteredTable={setMediaplanF}
                                colName={"NAZEV_MEDIA"}
                              />
                            </div>
                            <div class="table__col table__col--w200">
                              <label>Formát</label>
                              <Filter
                                filter={filter}
                                setFilter={setFilter}
                                filterVal={filterVal}
                                setFilterVal={setFilterVal}
                                table={mediaplan}
                                filterInput={filterInput}
                                setFilterInput={setFilterInput}
                                setFilteredTable={setMediaplanF}
                                colName={"FORMAT"}
                              />
                            </div>
                            <div class="table__col table__col--w200">
                              <label>Datum uveřejnění</label>
                              <Filter
                                filter={filter}
                                setFilter={setFilter}
                                filterVal={filterVal}
                                setFilterVal={setFilterVal}
                                table={mediaplan}
                                filterInput={filterInput}
                                setFilterInput={setFilterInput}
                                setFilteredTable={setMediaplanF}
                                colName={"DATUM_UVEREJNENI"}
                              />
                            </div>
                            {props.user >= 4 || props.user == 1 ? (
                              <>
                                <div class="table__col table__col--w160 table__col--agentura-header">
                                  <label>Agentura Gross</label>
                                </div>
                                <div class="table__col table__col--w160 table__col--agentura-header">
                                  <label>Agentura Příplatky</label>
                                </div>
                                <div class="table__col table__col--w160 table__col--agentura-header">
                                  <label>Agentura Slevy</label>
                                </div>
                                <div class="table__col table__col--w160 table__col--agentura-header">
                                  <label>Agentura NetNet</label>
                                </div>
                              </>
                            ) : (
                              <></>
                            )}
                            <div class="table__col table__col--w160 table__col--klient-header">
                              <label>Klient Gross</label>
                            </div>
                            <div class="table__col table__col--w160 table__col--klient-header">
                              <label>Klient Příplatky</label>
                            </div>
                            <div class="table__col table__col--w160 table__col--klient-header">
                              <label>Klient Slevy</label>
                            </div>
                            <div class="table__col table__col--flexible table__col--klient-header">
                              <label>Klient NetNet</label>
                            </div>
                          </>
                        ) : (
                          <></>
                        )}
                        {params.mediatyp == "OOH" ||
                        params.mediatyp == "Cinema" ? (
                          <>
                            <div class="table__col table__col--w200">
                              <label>Dodavatel</label>
                              <Filter
                                filter={filter}
                                setFilter={setFilter}
                                filterVal={filterVal}
                                setFilterVal={setFilterVal}
                                table={mediaplan}
                                filterInput={filterInput}
                                setFilterInput={setFilterInput}
                                setFilteredTable={setMediaplanF}
                                colName={"VYDAVATEL"}
                              />
                            </div>
                            <div class="table__col table__col--w200">
                              <label>Název média</label>
                              <Filter
                                filter={filter}
                                setFilter={setFilter}
                                filterVal={filterVal}
                                setFilterVal={setFilterVal}
                                table={mediaplan}
                                filterInput={filterInput}
                                setFilterInput={setFilterInput}
                                setFilteredTable={setMediaplanF}
                                colName={"NAZEV_MEDIA"}
                              />
                            </div>
                            <div class="table__col table__col--w200">
                              <label>Formát</label>
                              <Filter
                                filter={filter}
                                setFilter={setFilter}
                                filterVal={filterVal}
                                setFilterVal={setFilterVal}
                                table={mediaplan}
                                filterInput={filterInput}
                                setFilterInput={setFilterInput}
                                setFilteredTable={setMediaplanF}
                                colName={"FORMAT"}
                              />
                            </div>
                            <div class="table__col table__col--w200">
                              <label>Datum uveřejnění</label>
                              <Filter
                                filter={filter}
                                setFilter={setFilter}
                                filterVal={filterVal}
                                setFilterVal={setFilterVal}
                                table={mediaplan}
                                filterInput={filterInput}
                                setFilterInput={setFilterInput}
                                setFilteredTable={setMediaplanF}
                                colName={"DATUM_UVEREJNENI"}
                              />
                            </div>
                            {props.user >= 4 || props.user == 1 ? (
                              <>
                                <div class="table__col table__col--w160 table__col--agentura-header">
                                  <label>Agentura Gross</label>
                                </div>
                                <div class="table__col table__col--w160 table__col--agentura-header">
                                  <label>Agentura Slevy</label>
                                </div>
                                <div class="table__col table__col--w160 table__col--agentura-header">
                                  <label>Agentura Net</label>
                                </div>
                                <div class="table__col table__col--w160 table__col--agentura-header">
                                  <label>Agentura AP</label>
                                </div>
                                <div class="table__col table__col--w160 table__col--agentura-header">
                                  <label>Agentura NetNet</label>
                                </div>
                              </>
                            ) : (
                              <></>
                            )}
                            <div class="table__col table__col--w160 table__col--klient-header">
                              <label>Klient Gross</label>
                            </div>
                            <div class="table__col table__col--w160 table__col--klient-header">
                              <label>Klient Slevy</label>
                            </div>
                            <div class="table__col table__col--w160 table__col--klient-header">
                              <label>Klient Net</label>
                            </div>
                            <div class="table__col table__col--w160 table__col--klient-header">
                              <label>Klient AP</label>
                            </div>
                            <div class="table__col table__col--w160 table__col--klient-header">
                              <label>Klient NetNet</label>
                            </div>
                            <div class="table__col table__col--w160 table__col--klient-header">
                              <label>Klient Fee</label>
                            </div>
                            <div class="table__col table__col--flexible table__col--klient-header">
                              <label>Cost To Client</label>
                            </div>
                          </>
                        ) : (
                          <></>
                        )}
                      </div>
                    </div>

                    <div class="table__body">
                      {params.mediatyp == "TV" ? (
                        mediaplan.some(
                          (o) => o.ID_MEDIAPLAN_VERSION == vybranyMediaplan
                        ) ? (
                          mediaplanF
                            .filter(
                              (o) => o.ID_MEDIAPLAN_VERSION == vybranyMediaplan
                            )
                            .map((mediaplan, index) => (
                              <div
                                class={"table__row"}
                                key={mediaplan.ID_MEDIAPLAN_VERSION + index}
                              >
                                <div class="table__col table__col--w200">
                                  {mediaplan.OBCHODNI_SKUPINY}
                                </div>
                                <div class="table__col table__col--w120">
                                  {numberWithSpaces(mediaplan.STOPAZ)}
                                </div>
                                {props.user >= 4 || props.user == 1 ? (
                                  <div class="table__col table__col--w200 table__col--agentura">
                                    {numberWithSpaces(
                                      mediaplan.AGENTURA_NETNET
                                    )}
                                  </div>
                                ) : (
                                  <></>
                                )}
                                <div class="table__col table__col--w200 table__col--klient">
                                  {numberWithSpaces(mediaplan.CLIENT_NETNET)}
                                </div>

                                <div class="table__col table__col--w160 table__col--klient">
                                  {numberWithSpaces(mediaplan.CELKEM_GRP)}
                                </div>
                                <div class="table__col table__col--w160 table__col--klient">
                                  {numberWithSpaces(mediaplan.CELKEM_TRP)}
                                </div>
                                <div class="table__col table__col--w160 table__col--klient">
                                  {numberWithSpaces(mediaplan.AFFINITA)}
                                </div>
                                <div class="table__col table__col--flexible table__col--klient">
                                  {numberWithSpaces(mediaplan.POCET_SPOTU)}
                                </div>
                              </div>
                            ))
                        ) : (
                          mediaplanSPF
                            .filter(
                              (o) => o.ID_MEDIAPLAN_VERSION == vybranyMediaplan
                            )
                            .map((mediaplanSP, index) => (
                              <div
                                class={"table__row"}
                                key={mediaplanSP.ID_MEDIAPLAN_VERSION + index}
                              >
                                <div class="table__col table__col--w200">
                                  {mediaplanSP.VYDAVATEL}
                                </div>
                                <div class="table__col table__col--w200">
                                  {numberWithSpaces(
                                    mediaplanSP.MEDIA_GROSS_CELKEM
                                  )}
                                </div>
                                <div class="table__col table__col--w200">
                                  {numberWithSpaces(mediaplanSP.MEDIA_NET_NET)}
                                </div>
                                <div class="table__col table__col--w160">
                                  {numberWithSpaces(
                                    mediaplanSP.ODHAD_GRPS_CELKEM
                                  )}
                                </div>
                                <div class="table__col table__col--w160">
                                  {numberWithSpaces(
                                    mediaplanSP.ODHAD_TRPS_CELKEM
                                  )}
                                </div>
                                <div class="table__col table__col--flexible">
                                  {numberWithSpaces(mediaplanSP.AFINITA)}
                                </div>
                              </div>
                            ))
                        )
                      ) : (
                        <> </>
                      )}
                      {params.mediatyp == "Print" ||
                      params.mediatyp == "Radio" ? (
                        mediaplanF
                          .filter(
                            (o) => o.ID_MEDIAPLAN_VERSION == vybranyMediaplan
                          )
                          .map((mediaplan, index) => (
                            <div
                              class={"table__row"}
                              key={mediaplan.ID_MEDIAPLAN_VERSION + index}
                            >
                              <div class="table__col table__col--w200">
                                {mediaplan.VYDAVATEL}
                              </div>
                              <div class="table__col table__col--w200">
                                {mediaplan.NAZEV_MEDIA}
                              </div>
                              <div class="table__col table__col--w200">
                                {mediaplan.FORMAT}
                              </div>
                              <div class="table__col table__col--w200">
                                {mediaplan.DATUM_UVEREJNENI}
                              </div>
                              {props.user >= 4 || props.user == 1 ? (
                                <>
                                  <div class="table__col table__col--w160 table__col--agentura">
                                    {numberWithSpaces(mediaplan.GROSS_D)}
                                  </div>
                                  <div class="table__col table__col--w160 table__col--agentura">
                                    {numberWithSpaces(
                                      mediaplan.PRIPLATEK_D_czk
                                    )}
                                  </div>
                                  <div class="table__col table__col--w160 table__col--agentura">
                                    {numberWithSpaces(mediaplan.SLEVY_D)}
                                  </div>
                                  <div class="table__col table__col--w160 table__col--agentura">
                                    {numberWithSpaces(mediaplan.NET_NET_D)}
                                  </div>
                                </>
                              ) : (
                                <></>
                              )}
                              <div class="table__col table__col--w160 table__col--klient">
                                {numberWithSpaces(mediaplan.GROSS)}
                              </div>
                              <div class="table__col table__col--w160 table__col--klient">
                                {numberWithSpaces(mediaplan.PRIPLATEK_CZK)}
                              </div>
                              <div class="table__col table__col--w160 table__col--klient">
                                {numberWithSpaces(mediaplan.SLEVY)}
                              </div>
                              <div class="table__col table__col--flexible table__col--klient">
                                {numberWithSpaces(mediaplan.NET_NET)}
                              </div>
                            </div>
                          ))
                      ) : (
                        <></>
                      )}
                      {params.mediatyp == "OOH" ||
                      params.mediatyp == "Cinema" ? (
                        mediaplanF
                          .filter(
                            (o) => o.ID_MEDIAPLAN_VERSION == vybranyMediaplan
                          )
                          .map((mediaplan, index) => (
                            <div
                              class={"table__row"}
                              key={mediaplan.ID_MEDIAPLAN_VERSION + index}
                            >
                              <div class="table__col table__col--w200">
                                {mediaplan.VYDAVATEL}
                              </div>
                              <div class="table__col table__col--w200">
                                {mediaplan.NAZEV_MEDIA}
                              </div>
                              <div class="table__col table__col--w200">
                                {mediaplan.FORMAT}
                              </div>
                              <div class="table__col table__col--w200">
                                {mediaplan.DATUM_UVEREJNENI}
                              </div>
                              {props.user >= 4 || props.user == 1 ? (
                                <>
                                  <div class="table__col table__col--w160 table__col--agentura">
                                    {numberWithSpaces(mediaplan.GROSS_D)}
                                  </div>
                                  <div class="table__col table__col--w160 table__col--agentura">
                                    {numberWithSpaces(mediaplan.SLEVY_D)}
                                  </div>
                                  <div class="table__col table__col--w160 table__col--agentura">
                                    {numberWithSpaces(mediaplan.NET_D)}
                                  </div>
                                  <div class="table__col table__col--w160 table__col--agentura">
                                    {numberWithSpaces(
                                      mediaplan.AGENTURNI_PROVIZE_D
                                    )}
                                  </div>
                                  <div class="table__col table__col--w160 table__col--agentura">
                                    {numberWithSpaces(mediaplan.NET_NET_D)}
                                  </div>
                                </>
                              ) : (
                                <></>
                              )}
                              <div class="table__col table__col--w160 table__col--klient">
                                {numberWithSpaces(mediaplan.GROSS)}
                              </div>
                              <div
                                c
                                class="table__col table__col--w160 table__col--klient"
                              >
                                {numberWithSpaces(mediaplan.SLEVY)}
                              </div>
                              <div class="table__col table__col--w160 table__col--klient">
                                {numberWithSpaces(mediaplan.NET)}
                              </div>
                              <div class="table__col table__col--w160 table__col--klient">
                                {numberWithSpaces(mediaplan.AGENTURNI_PROVIZE)}
                              </div>
                              <div class="table__col table__col--w160 table__col--klient">
                                {numberWithSpaces(mediaplan.NET_NET)}
                              </div>
                              <div class="table__col table__col--w160 table__col--klient">
                                {numberWithSpaces(mediaplan.FEE)}
                              </div>
                              <div class="table__col table__col--flexible table__col--klient">
                                {numberWithSpaces(mediaplan.CNNN)}
                              </div>
                            </div>
                          ))
                      ) : (
                        <></>
                      )}
                      {params.mediatyp == "Print" ||
                      params.mediatyp == "Radio" ? (
                        <div class="table__row" key="sumacny">
                          <div class="table__col table__col--w200 table__col--hidden"></div>
                          <div class="table__col table__col--w200 table__col--hidden"></div>
                          <div class="table__col table__col--w200 table__col--hidden"></div>
                          <div class="table__col table__col--w200 table__col--hidden"></div>
                          {props.user >= 4 || props.user == 1 ? (
                            <>
                              <div class="table__col table__col--w160 table__col--highlight-red">
                                {numberWithSpaces(
                                  mediaplanF
                                    .filter(
                                      (o) =>
                                        o.ID_MEDIAPLAN_VERSION ==
                                        vybranyMediaplan
                                    )
                                    .reduce((n, { GROSS_D }) => n + GROSS_D, 0)
                                )}
                              </div>
                              <div class="table__col table__col--w160 table__col--highlight-red">
                                {numberWithSpaces(
                                  mediaplanF
                                    .filter(
                                      (o) =>
                                        o.ID_MEDIAPLAN_VERSION ==
                                        vybranyMediaplan
                                    )
                                    .reduce(
                                      (n, { PRIPLATEK_D_czk }) =>
                                        n + PRIPLATEK_D_czk,
                                      0
                                    )
                                )}
                              </div>
                              <div class="table__col table__col--w160 table__col--highlight-red">
                                {numberWithSpaces(
                                  mediaplanF
                                    .filter(
                                      (o) =>
                                        o.ID_MEDIAPLAN_VERSION ==
                                        vybranyMediaplan
                                    )
                                    .reduce((n, { SLEVY_D }) => n + SLEVY_D, 0)
                                )}
                              </div>
                              <div class="table__col table__col--w160 table__col--highlight-red">
                                {numberWithSpaces(
                                  mediaplanF
                                    .filter(
                                      (o) =>
                                        o.ID_MEDIAPLAN_VERSION ==
                                        vybranyMediaplan
                                    )
                                    .reduce(
                                      (n, { NET_NET_D }) => n + NET_NET_D,
                                      0
                                    )
                                )}
                              </div>
                            </>
                          ) : (
                            <></>
                          )}

                          <div class="table__col table__col--w160 table__col--highlight-green">
                            {numberWithSpaces(
                              mediaplanF
                                .filter(
                                  (o) =>
                                    o.ID_MEDIAPLAN_VERSION == vybranyMediaplan
                                )
                                .reduce((n, { GROSS }) => n + GROSS, 0)
                            )}
                          </div>
                          <div class="table__col table__col--w160 table__col--highlight-green">
                            {numberWithSpaces(
                              mediaplanF
                                .filter(
                                  (o) =>
                                    o.ID_MEDIAPLAN_VERSION == vybranyMediaplan
                                )
                                .reduce(
                                  (n, { PRIPLATEK_CZK }) => n + PRIPLATEK_CZK,
                                  0
                                )
                            )}
                          </div>
                          <div class="table__col table__col--w160 table__col--highlight-green">
                            {numberWithSpaces(
                              mediaplanF
                                .filter(
                                  (o) =>
                                    o.ID_MEDIAPLAN_VERSION == vybranyMediaplan
                                )
                                .reduce((n, { SLEVY }) => n + SLEVY, 0)
                            )}
                          </div>
                          <div class="table__col table__col--flexible table__col--highlight-green">
                            {numberWithSpaces(
                              mediaplanF
                                .filter(
                                  (o) =>
                                    o.ID_MEDIAPLAN_VERSION == vybranyMediaplan
                                )
                                .reduce((n, { NET_NET }) => n + NET_NET, 0)
                            )}
                          </div>
                        </div>
                      ) : (
                        <></>
                      )}
                      {params.mediatyp == "OOH" ||
                      params.mediatyp == "Cinema" ? (
                        <div class="table__row" key="sumacny">
                          <div class="table__col table__col--w200 table__col--hidden"></div>
                          <div class="table__col table__col--w200 table__col--hidden"></div>
                          <div class="table__col table__col--w200 table__col--hidden"></div>
                          <div class="table__col table__col--w200 table__col--hidden"></div>
                          {props.user >= 4 || props.user == 1 ? (
                            <>
                              <div class="table__col table__col--w160 table__col--highlight-red">
                                {numberWithSpaces(
                                  mediaplanF
                                    .filter(
                                      (o) =>
                                        o.ID_MEDIAPLAN_VERSION ==
                                        vybranyMediaplan
                                    )
                                    .reduce((n, { GROSS_D }) => n + GROSS_D, 0)
                                )}
                              </div>

                              <div class="table__col table__col--w160 table__col--highlight-red">
                                {numberWithSpaces(
                                  mediaplanF
                                    .filter(
                                      (o) =>
                                        o.ID_MEDIAPLAN_VERSION ==
                                        vybranyMediaplan
                                    )
                                    .reduce((n, { SLEVY_D }) => n + SLEVY_D, 0)
                                )}
                              </div>
                              <div class="table__col table__col--w160 table__col--highlight-red">
                                {numberWithSpaces(
                                  mediaplanF
                                    .filter(
                                      (o) =>
                                        o.ID_MEDIAPLAN_VERSION ==
                                        vybranyMediaplan
                                    )
                                    .reduce((n, { NET_D }) => n + NET_D, 0)
                                )}
                              </div>
                              <div class="table__col table__col--w160 table__col--highlight-red">
                                {numberWithSpaces(
                                  mediaplanF
                                    .filter(
                                      (o) =>
                                        o.ID_MEDIAPLAN_VERSION ==
                                        vybranyMediaplan
                                    )
                                    .reduce(
                                      (n, { AGENTURNI_PROVIZE_D }) =>
                                        n + AGENTURNI_PROVIZE_D,
                                      0
                                    )
                                )}
                              </div>
                              <div class="table__col table__col--w160 table__col--highlight-red">
                                {numberWithSpaces(
                                  mediaplanF
                                    .filter(
                                      (o) =>
                                        o.ID_MEDIAPLAN_VERSION ==
                                        vybranyMediaplan
                                    )
                                    .reduce(
                                      (n, { NET_NET_D }) => n + NET_NET_D,
                                      0
                                    )
                                )}
                              </div>
                            </>
                          ) : (
                            <></>
                          )}
                          <div class="table__col table__col--w160 table__col--highlight-green">
                            {numberWithSpaces(
                              mediaplanF
                                .filter(
                                  (o) =>
                                    o.ID_MEDIAPLAN_VERSION == vybranyMediaplan
                                )
                                .reduce((n, { GROSS }) => n + GROSS, 0)
                            )}
                          </div>

                          <div class="table__col table__col--w160 table__col--highlight-green">
                            {numberWithSpaces(
                              mediaplanF
                                .filter(
                                  (o) =>
                                    o.ID_MEDIAPLAN_VERSION == vybranyMediaplan
                                )
                                .reduce((n, { SLEVY }) => n + SLEVY, 0)
                            )}
                          </div>
                          <div class="table__col table__col--w160 table__col--highlight-green">
                            {numberWithSpaces(
                              mediaplanF
                                .filter(
                                  (o) =>
                                    o.ID_MEDIAPLAN_VERSION == vybranyMediaplan
                                )
                                .reduce((n, { NET }) => n + NET, 0)
                            )}
                          </div>
                          <div class="table__col table__col--w160 table__col--highlight-green">
                            {numberWithSpaces(
                              mediaplanF
                                .filter(
                                  (o) =>
                                    o.ID_MEDIAPLAN_VERSION == vybranyMediaplan
                                )
                                .reduce(
                                  (n, { AGENTURNI_PROVIZE }) =>
                                    n + AGENTURNI_PROVIZE,
                                  0
                                )
                            )}
                          </div>
                          <div class="table__col table__col--w160 table__col--highlight-green">
                            {numberWithSpaces(
                              mediaplanF
                                .filter(
                                  (o) =>
                                    o.ID_MEDIAPLAN_VERSION == vybranyMediaplan
                                )
                                .reduce((n, { NET_NET }) => n + NET_NET, 0)
                            )}
                          </div>
                          <div class="table__col table__col--w160 table__col--highlight-green">
                            {numberWithSpaces(
                              mediaplanF
                                .filter(
                                  (o) =>
                                    o.ID_MEDIAPLAN_VERSION == vybranyMediaplan
                                )
                                .reduce((n, { FEE }) => n + FEE, 0)
                            )}
                          </div>
                          <div class="table__col table__col--flexible table__col--highlight-green">
                            {numberWithSpaces(
                              mediaplanF
                                .filter(
                                  (o) =>
                                    o.ID_MEDIAPLAN_VERSION == vybranyMediaplan
                                )
                                .reduce((n, { CNNN }) => n + CNNN, 0)
                            )}
                          </div>
                        </div>
                      ) : (
                        <></>
                      )}
                      {params.mediatyp == "TV" ? (
                        mediaplan.some(
                          (o) => o.ID_MEDIAPLAN_VERSION == vybranyMediaplan
                        ) ? (
                          <div class="table__row" key="sumacny">
                            <div class="table__col table__col--w200 table__col--hidden"></div>
                            <div class="table__col table__col--w120 table__col--hidden"></div>
                            {props.user >= 4 || props.user == 1 ? (
                              <div class="table__col table__col--w200 table__col--highlight-red">
                                {numberWithSpaces(
                                  mediaplanF
                                    .filter(
                                      (o) =>
                                        o.ID_MEDIAPLAN_VERSION ==
                                        vybranyMediaplan
                                    )
                                    .reduce(
                                      (n, { AGENTURA_NETNET }) =>
                                        n + AGENTURA_NETNET,
                                      0
                                    )
                                )}
                              </div>
                            ) : (
                              <></>
                            )}
                            <div class="table__col table__col--w200 table__col--highlight-green">
                              {numberWithSpaces(
                                mediaplanF
                                  .filter(
                                    (o) =>
                                      o.ID_MEDIAPLAN_VERSION == vybranyMediaplan
                                  )
                                  .reduce(
                                    (n, { CLIENT_NETNET }) => n + CLIENT_NETNET,
                                    0
                                  )
                              )}
                            </div>

                            <div class="table__col table__col--w160 table__col--highlight-green">
                              {numberWithSpaces(
                                mediaplanF
                                  .filter(
                                    (o) =>
                                      o.ID_MEDIAPLAN_VERSION == vybranyMediaplan
                                  )
                                  .reduce(
                                    (n, { CELKEM_GRP }) => n + CELKEM_GRP,
                                    0
                                  )
                              )}
                            </div>
                            <div class="table__col table__col--w160 table__col--highlight-green">
                              {numberWithSpaces(
                                mediaplanF
                                  .filter(
                                    (o) =>
                                      o.ID_MEDIAPLAN_VERSION == vybranyMediaplan
                                  )
                                  .reduce(
                                    (n, { CELKEM_TRP }) => n + CELKEM_TRP,
                                    0
                                  )
                              )}
                            </div>
                            <div class="table__col table__col--w160 table__col--highlight-green">
                              {numberWithSpaces(
                                mediaplanF
                                  .filter(
                                    (o) =>
                                      o.ID_MEDIAPLAN_VERSION == vybranyMediaplan
                                  )
                                  .reduce((n, { AFFINITA }) => n + AFFINITA, 0)
                              )}
                            </div>
                            <div class="table__col table__col--flexible table__col--highlight-green">
                              {numberWithSpaces(
                                mediaplanF
                                  .filter(
                                    (o) =>
                                      o.ID_MEDIAPLAN_VERSION == vybranyMediaplan
                                  )
                                  .reduce(
                                    (n, { POCET_SPOTU }) => n + POCET_SPOTU,
                                    0
                                  )
                              )}
                            </div>
                          </div>
                        ) : (
                          <div class="table__row" key="sumacny">
                            <div class="table__col table__col--w200 table__col--hidden"></div>
                            <div class="table__col table__col--w200 table__col--highlight-green">
                              {numberWithSpaces(
                                mediaplanSPF
                                  .filter(
                                    (o) =>
                                      o.ID_MEDIAPLAN_VERSION == vybranyMediaplan
                                  )
                                  .reduce(
                                    (n, { MEDIA_GROSS_CELKEM }) =>
                                      n + MEDIA_GROSS_CELKEM,
                                    0
                                  )
                              )}
                            </div>
                            <div class="table__col table__col--w200 table__col--highlight-green">
                              {numberWithSpaces(
                                mediaplanSPF
                                  .filter(
                                    (o) =>
                                      o.ID_MEDIAPLAN_VERSION == vybranyMediaplan
                                  )
                                  .reduce(
                                    (n, { MEDIA_NET_NET }) => n + MEDIA_NET_NET,
                                    0
                                  )
                              )}
                            </div>
                            <div class="table__col table__col--w160 table__col--highlight-green">
                              {numberWithSpaces(
                                mediaplanSPF
                                  .filter(
                                    (o) =>
                                      o.ID_MEDIAPLAN_VERSION == vybranyMediaplan
                                  )
                                  .reduce(
                                    (n, { ODHAD_GRPS_CELKEM }) =>
                                      n + ODHAD_GRPS_CELKEM,
                                    0
                                  )
                              )}
                            </div>
                            <div class="table__col table__col--w160 table__col--highlight-green">
                              {numberWithSpaces(
                                mediaplanSPF
                                  .filter(
                                    (o) =>
                                      o.ID_MEDIAPLAN_VERSION == vybranyMediaplan
                                  )
                                  .reduce(
                                    (n, { ODHAD_TRPS_CELKEM }) =>
                                      n + ODHAD_TRPS_CELKEM,
                                    0
                                  )
                              )}
                            </div>
                            <div class="table__col table__col--flexible table__col--highlight-green">
                              {numberWithSpaces(
                                mediaplanSPF
                                  .filter(
                                    (o) =>
                                      o.ID_MEDIAPLAN_VERSION == vybranyMediaplan
                                  )
                                  .reduce((n, { AFINITA }) => n + AFINITA, 0)
                              )}
                            </div>
                          </div>
                        )
                      ) : (
                        <> </>
                      )}
                    </div>
                  </div>
                  {params.mediatyp !== "TV" ? (
                    <div class="action-bar">
                      {props.user < 6 ? (
                        <div class="action-bar__in">
                          <a
                            class="btn btn--medium btn--primary"
                            onClick={() => {
                              setModal(!modal);
                              setSubstitution(false);
                            }}
                          >
                            Import mediaplánu
                          </a>
                          {mediaplanVerzia
                            .filter((o) => o.ID == vybranyMediaplan)
                            .map((mv) =>
                              mv.CONFIRMED == 1 &&
                              (params.mediatyp == "Print" ||
                                params.mediatyp == "Radio" ||
                                params.mediatyp == "OOH") ? (
                                <a
                                  class="btn btn--medium btn--bordered"
                                  onClick={() => {
                                    setModal(!modal);
                                    GetMediaplanFeAndFileName();
                                    setSubstitution(true);
                                  }}
                                >
                                  Nahradit mediaplán
                                </a>
                              ) : (
                                <></>
                              )
                            )}
                          {params.mediatyp !== "OOH" ? (
                            <a
                              class="btn btn--medium btn--transparent"
                              onClick={() => {
                                setModalExType("odb");
                                setModalex(!modalex);
                                GetMediaplanFeAndFileName();
                              }}
                            >
                              Klientský export
                            </a>
                          ) : (
                            <></>
                          )}
                          {params.mediatyp !== "TV" ? (
                            <a
                              class="btn btn--medium btn--transparent"
                              onClick={() => {
                                setModalExType("dod");
                                setModalex(!modalex);
                                GetMediaplanFeAndFileName();
                              }}
                            >
                              Dodavatelský export
                            </a>
                          ) : (
                            <></>
                          )}
                          {mediaplanVerzia
                            .filter((o) => o.ID == vybranyMediaplan)
                            .map((mv) =>
                              mv.CONFIRMED == 0 || mv.CONFIRMED == null ? (
                                <a
                                  class="btn btn--medium btn--red"
                                  onClick={() => {
                                    setModalConfirmation(true);
                                  }}
                                >
                                  Odstránit mediaplán
                                </a>
                              ) : (
                                <></>
                              )
                            )}
                        </div>
                      ) : (
                        <></>
                      )}
                    </div>
                  ) : (
                    <div class="action-bar">
                      {props.user < 6 ? (
                        <div class="action-bar__in">
                          {!newImport ? (
                            <>
                              <a
                                class="btn btn--medium btn--primary"
                                onClick={() => {
                                  setNewImport(true);
                                }}
                              >
                                Import nového mediaplánu
                              </a>
                              <a
                                class="btn btn--medium btn--primary"
                                onClick={() => {
                                  setModal(!modal);
                                  setSubstitution(false);
                                  setSpotList(true);
                                }}
                              >
                                Import spotlistu
                              </a>
                              {mediaplanVerzia
                                .filter((o) => o.ID == vybranyMediaplan)
                                .map((mv) =>
                                  mv.CONFIRMED == 1 &&
                                  mediaplanSP.some(
                                    (o) => o.ID_MEDIAPLAN_VERSION === mv.ID
                                  ) ? (
                                    <a
                                      class="btn btn--medium btn--bordered"
                                      onClick={() => {
                                        setModal(!modal);
                                        setSubstitution(true);
                                      }}
                                    >
                                      Nahradit mediaplán
                                    </a>
                                  ) : (
                                    <></>
                                  )
                                )}
                              <a
                                class="btn btn--medium btn--transparent"
                                onClick={() => {
                                  setModalExType("odb");
                                  setModalex(!modalex);
                                  GetMediaplanFeAndFileName();
                                }}
                              >
                                Klientský export
                              </a>
                              {mediaplanVerzia
                                .filter((o) => o.ID == vybranyMediaplan)
                                .map((mv) =>
                                  mv.CONFIRMED == 0 || mv.CONFIRMED == null ? (
                                    <a
                                      class="btn btn--medium btn--red"
                                      onClick={() => {
                                        setModalConfirmation(true);
                                      }}
                                    >
                                      Odstránit mediaplán
                                    </a>
                                  ) : (
                                    <></>
                                  )
                                )}
                            </>
                          ) : (
                            <>
                              <a
                                class="btn btn--medium btn--primary"
                                onClick={() => {
                                  setModal(!modal);
                                  setSubstitution(false);
                                }}
                              >
                                Import cen/sponzoring
                              </a>
                              <a
                                class="btn btn--medium btn--primary"
                                onClick={() => {
                                  NemereneStaniceImport();
                                  setSubstitution(false);
                                }}
                              >
                                Import cen - bez souboru
                              </a>

                              <a
                                class="btn btn--medium btn--bordered"
                                onClick={() => {
                                  setNewImport(false);
                                }}
                              >
                                Zpět
                              </a>
                            </>
                          )}
                        </div>
                      ) : (
                        <></>
                      )}
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        )}
      </main>
    </>
  );
}
